
    import Vue from "vue";
    import Component from "vue-class-component";
    import { StateChanger } from "vue-infinite-loading";
    import ApiButton from "@/components/ApiButton.vue";
    import { required, email } from "vuelidate/lib/validators";
    import { Validations } from "vuelidate-property-decorators";
    import { Watch } from "vue-property-decorator";
    import * as toastr from "toastr";

    import store from "@/store/store";
    import apiClient from "@/stuff/ApiClient";
    import { InsuranceTypeSearchParameters } from "@/model/InsuranceTypeSearchParameters";
    import { LookupItem, ILookupItem } from "@/model/LookupItem";
    import { InsuranceType, IInsuranceType } from "@/model/InsuranceType";
    import utils from "@/stuff/Utils";
    import { TriState } from "@/model/Enums";

    @Component({
        components: { ApiButton }
    })
    export default class InsuranceTypes extends Vue {

        //
        // -- lifecycle hooks (https://vuejs.org/v2/guide/instance.html#Instance-Lifecycle-Hooks)
        //

        mounted() {
            // load lookups - not using async / await - happy to just fire everything off and let Vue worry about rendering / re-rendering
            store.dispatch("loadCompanyTypeList");
            store.dispatch("loadCountryList");
            // don't search here - infinite-loading component will do that...
        }

        beforeDestroy() {
            // If we're using any 3rd party gizmos in a component we need to release
            // any resources(call .destroy, etc.or whatever) to avoid memory leaks.
        }

        //
        // -- properties
        //

        private searchParameters = new InsuranceTypeSearchParameters();
        private totalCount = -1;
        private insuranceTypeList: Array<IInsuranceType> = [];
        private readonly insuranceType = new InsuranceType();

        // computed
        private get dialogueTitle(): string {
            if (!this.insuranceType) return "- - -";
            if (utils.isEmptyId(this.insuranceType.id)) return "New InsuranceType";
            return "Edit " + this.insuranceType.description;
        }

        private get countText(): string {
            return this.totalCount === -1 ? "..." : this.totalCount.toString();
        }

        private get isDeletedSearchOptions(): Array<LookupItem> { return [
            new LookupItem({ id: TriState.UseDefault, description: "All", isArchived: false }),
            new LookupItem({ id: TriState.True, description: "Deleted", isArchived: false }),
            new LookupItem({ id: TriState.False, description: "Not deleted", isArchived: false })
        ];}

        //
        // -- Watchers
        //

        @Watch("searchParameters.isDeleted")
        onIsDeletedChanged(value: number) {
            console.log("Is deleted changed = ", value);
            this.refreshSearch();
        }

        //
        // -- methods
        //

        async edit(insuranceTypeData: IInsuranceType) {
            this.insuranceType.update(insuranceTypeData);
            // reset any validation
            this.$v.$reset();
            // show the dialogue
            this.$bvModal.show("insuranceTypeDialogue");
			// refresh from server
            const serverInsuranceTypeData = await apiClient.get(`api/insuranceType/Load?id=${insuranceTypeData.id}`);
            this.insuranceType.update(serverInsuranceTypeData);
        }

        editNew() {
            utils.resetObject(this.insuranceType);
            this.$v.$reset();
            this.$bvModal.show("insuranceTypeDialogue");
        }

        cancel() {
            this.$bvModal.hide("insuranceTypeDialogue");
            utils.resetObject(this.insuranceType);
        }

        async save(event: Event) {

            // 'touch' all the fields to activate the validation messages
            this.$v.$touch();
            if (this.$v.insuranceType.$invalid) {
                toastr.info("Please fix the highlighted errors", "Validation errors");
                return;
            }
            const response: { newID: number } =  await apiClient.post("/api/insuranceType/save", this.insuranceType, event);
            if (this.insuranceType.isNew) {
                this.insuranceType.id = response.newID;
            }
            toastr.success("Saved");           
            this.$bvModal.hide("insuranceTypeDialogue");
            // redo search - or should we just update in place?
            this.refreshSearch();
        }

        // 'delete' is a reserved word
        async deleteItem(event: Event) {
            if (this.insuranceType.isNew) return;
            const shouldDelete: boolean = await this.$bvModal.msgBoxConfirm("Do you want to delete '" + this.insuranceType.description + "'?", {
                title: "Delete",
                okVariant: "danger",
                okTitle: "Yes, delete!",
                cancelTitle: "No, leave it",
                hideHeaderClose: true,
                centered: true,
                headerClass: "border-bottom-0",
                footerClass: "border-top-0",
                size: "sm"
            });
            if (!shouldDelete) return;
            await apiClient.post("/api/insuranceType/delete", this.insuranceType, event);
            toastr.warning("Deleted");
            this.$bvModal.hide("insuranceTypeDialogue");
            this.refreshSearch();
        }

        refreshSearch(event?: Event) {
            this.insuranceTypeList = [];
            this.searchParameters.pageNumber = 1;
            this.totalCount = -1;
            this.search(event);
        }

        async infiniteLoadingHandler(stateChanger: StateChanger) {
            if (this.insuranceTypeList.length >= this.totalCount && this.totalCount > -1) {
                stateChanger.complete();
                return;
            }
            this.searchParameters.pageNumber += 1;
            await this.search();
            if (this.insuranceTypeList.length >= this.totalCount) {
                stateChanger.complete();
            }
            else {
                stateChanger.loaded();
            }           
        }

        private async search(event?: Event) {
            const response = await apiClient.post("/Api/InsuranceType/Search", this.searchParameters, event);
            if (this.searchParameters.pageNumber === 1) {
                this.totalCount = response.count;
            }
            this.insuranceTypeList.push(...response.list.map((s: IInsuranceType) => new InsuranceType(s)));
        }

        @Validations()
        validations() {
            const validations = {
                insuranceType: {} as any // eslint-disable-line @typescript-eslint/no-explicit-any
            };
            validations.insuranceType.description = { required };
            return validations;
        }
    }
