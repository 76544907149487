import utils from "@/stuff/Utils";
import { mapData } from "@/stuff/DataMapping";

export interface IInsuranceType {
    id: number;
    description: string;
    isMandatory: boolean;
    created: Date;
    createdByUserID: number;
    lastUpdated: Date;
    lastUpdatedByUserID: number;
    deleted: Date;
    deletedByUserID: number;
}

export class InsuranceType implements IInsuranceType {

    constructor(data?: IInsuranceType) {
        if (typeof data === "undefined" || data === null) return;
        this.update(data);
    }

    update(data: IInsuranceType) {
        mapData(data, { root: () => this });
    }

    id: number = 0;
    description: string = "";
    isMandatory: boolean = false;
    created: Date = new Date(utils.emptyDateValue);
    createdByUserID: number = 0;
    lastUpdated: Date = new Date(utils.emptyDateValue);
    lastUpdatedByUserID: number = 0;
    deleted: Date = new Date(utils.emptyDateValue);
    deletedByUserID: number = 0;

    get isNew() {
        return utils.isEmptyId(this.id);
    }

    get isDeleted() {
        return utils.hasDateValue(this.deleted);
    }
}