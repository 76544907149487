import { render, staticRenderFns } from "./InsuranceTypes.vue?vue&type=template&id=295a5946&scoped=true"
import script from "./InsuranceTypes.vue?vue&type=script&lang=ts"
export * from "./InsuranceTypes.vue?vue&type=script&lang=ts"
import style0 from "./InsuranceTypes.vue?vue&type=style&index=0&id=295a5946&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "295a5946",
  null
  
)

export default component.exports